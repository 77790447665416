import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe, FaAndroid, FaReact, FaApple, FaNodeJs, FaNode} from "react-icons/fa";
import { SiProgress, SiAntdesign } from "react-icons/si";

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <FaAndroid />,
    title: "Android Development",
    des: "Drawing from my substantial background in Android development utilizing Kotlin and Java, I have the ability to craft streamlined and user-centric apps tailored to your specific needs. I prioritize close collaboration with clients to translate their visions into reality while enhancing app performance and functionality. Opt for my Android development services to enjoy the rewarding experience of partnering with a seasoned professional dedicated to your project's success.",
  },
  {
    id: 3,
    icon: <FaApple />,
    title: "iOS Development",
    des: "Bringing my expertise as a seasoned iOS developer focused on Swift, I design outstanding iOS apps that surpass expectations. Collaborating closely with clients, I ensure their distinct needs are met and their creative visions are actualized. With a dedication to excellence and in-depth iOS development skills, I guarantee your app will achieve peak performance and functionality. Opt for my iOS development services to give life to your app and experience the difference.",
  },
  {
    id: 4,
    icon: <FaReact />,
    title: "React-Native Development",
    des: "I specialize in developing cross-platform mobile apps using React Native, drawing from my experience in native Android and iOS development. Crafting customized apps to fit your needs is my priority, and I emphasize collaboration to ensure your vision comes to life with optimized performance. With me, you're getting a dedicated professional focused on making your React Native project a success.",
  },
  // {
  //   id: 5,
  //   icon: <FaNode />,
  //   title: "MERN Stack Development",
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque solutahic consequuntur eum repellendus ad",
  // },
];
