import React from "react";
import { BsGithub } from "react-icons/bs";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";
import { Store } from "../../data/projectsData";

const ProjectsCard = ({ project }) => {
  return (
    <div className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000">
      <div className="w-full h-auto overflow-hidden rounded-lg">
        <img
          className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
          src={project.cover}
          alt="src"
        />
      </div>
      <div className="w-full mt-6 flex flex-col  gap-6">
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-base uppercase text-designColor font-normal">
              {project.title}
            </h3>
            <div className="flex gap-2">
              {project.links.map((link) => {
                return (
                  <a href={link.link}>
                    <span className="text-lg w-10 h-10 rounded-full bg-black inline-flex justify-center items-center text-gray-400 hover:text-designColor duration-300 cursor-pointer">
                      {link.store == Store.GOOGLE ? (
                        <FaGooglePlay />
                      ) : (
                        <FaAppStore />
                      )}
                    </span>
                  </a>
                );
              })}
            </div>
          </div>
          <p className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300">
            {project.des}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCard;
